<template>
  <div class="col-md-10 mx-auto py3 text-center">
    <div class="textAndEmoji"><p class="mb-0">Bom descanso!</p><img v-if="(this.sizeWindow < 768)" src="../../assets/image/iconesMobile/emojiLogout.svg" width="auto" height="20px"></div>
    <p class="mb-3" id="name">
      <strong>{{name}}</strong>
    </p>

    <p v-if="(this.sizeWindow >=768)">
      Nos vemos em breve!
      <br />
      <br />=)
    </p>
    <div class="routeCont">
      <router-link :to="'/unidadeCpf'" class="outra-conta" v-if="this.sizeWindow < 768">
        {{$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?'}}<br>
        <strong>{{$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'}}</strong>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name"],
  data(){
    return{
      sizeWindow: 0
    }
  },
  mounted(){
    this.sizeWindow = $(window).width();
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    }
  }
};
</script>

<style scoped>
p {
  color: #868686;
}
strong {
  color: #2474b2;
}

.routeCont{
  margin-top: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.routeCont a{
  color: #A5A6AD;
}
@media screen and (max-width:767px){
  label{
    font-size: 16px;
  }
  p{
    font-size: 16px;
  }
  .outra-conta{
    font-size: 16px;
  }
  .textAndEmoji{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  #name strong{
    color: #F38235;
  }
}
</style>