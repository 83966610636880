<template>
  <div>
    <!-- <transition name="extra"> -->
    <transition name="fade"  mode="out-in">
      <component
        :is="cp"
        :name="name"
        :totalValue.sync="totalValue"
        @trocaComponent="trocaComponent"
        :item="data"
        @finish="finishBox"
        @back="trocaComponent"
      />
    </transition>
  </div>
</template>

<script>
import fechar_caixa from "@/components/modal_logout/_Fechar_caixa";
import despedida from "@/components/modal_logout/_Despedida";
import retirarDinheiro from "@/components/modal_logout/_retiradaDinheiro";
import comfirm from "@/components/modal_logout/_Confirm";
import HTTP from "@/api_system";
export default {
  name: "home",
  components: {
    fechar_caixa,
    despedida,
    retirarDinheiro,
    comfirm,
  },
  data() {
    return {
      cp: "",
      logoSmall: "",
      name: "",
      totalValue: "",
      data: [],
      itemsResult: '',
      sessionStorageValue: undefined,
      sessionStorageRetirada: undefined,
    };
  },
  mounted() {
    this.name = this.user.displayName;
    if (this.$store.getters.getUser.profile != 3) {
      this.cp = "despedida";
      this.$store.dispatch("logoff");
    } else {
      (this.logoSmall = "logoSmall"), (this.cp = "fechar_caixa");
    }
  },
  methods: {
    trocaComponent(components) {
      this.logoSmall = "";
      this.cp = components;
    },
    getListBox() { //! excluir
      HTTP.get("services/app/Cashier/GetCashierList", {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`,
        },
      })
        .then((response) => {
          this.data = response.data.result.actualMonth[0].listCashier[0];
          // console.log(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    finishBox() {
      HTTP.post(
        `services/app/Cashier/ClosingCashier?finished=true`,
        {
          id: this.$store.state.caixa.idCaixa,
          endValue: this.$store.getters.valueCaixa,
          endPrints: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`,
          },
        }
      )
        .then(() => {
          this.$store
            .dispatch("logoff")
            .then((resolve) => {
              if (resolve) {
                var largura = window.screen.width;
                this.$store.commit("valorCx", 0);
                this.cp = "despedida";
                if(largura < 768){
                  this.$router.push('finishMobile')
                }
                sessionStorage.clear()
              }
            })
            .catch(() => {
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i> Ops algo deu errado`,
              });
            });
        })
        .catch((error) => {
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i> ${error.response.data.error.message}`,
          });
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    },
  },
  watch: {
    cp: function (current) {
      if (current === 'comfirm') {
        this.data = this.$store.getters.fecharCxValues[0]
      }
    }
  }
};
</script>
<style scoped>
.grads {
  background: linear-gradient(to bottom, #f28236 0%, #e0a638 100%);
}
.logo_modal_login {
  height: 90px;
  width: auto;
}
.box_modal_login {
  background: #fff;
  border-radius: 20px;
  min-height: 400px;
  overflow: hidden;
}
.logoSmall {
  height: 50px !important;
  /* opacity: 0; */
  width: auto;
}
.login {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f28236+0,e0a638+100 */
  height: 100vh !important;

  overflow: hidden;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
