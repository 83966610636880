<template>
  <div class="containerCaixa">
    <div class="nameAndImg" v-if="(this.sizeWindow < 768)">
			<div class="imgName">
				<div class="image_div position-relative mx-auto">
					<img :src="getImg(user.photo)" class="img_user" @error="errorImage" />
				</div>
			</div>
			<div class="name">
				<p class="titleMobile">
				Olá,
				<br />
				<strong>{{ user.displayName }}!</strong>
				</p>
			</div>
		</div>
    <div class="col-md-12 pr-0 text-center" v-if="(this.sizeWindow < 768)">
      <p class="title">
        Obrigado pelo trabalho<img  src="../../assets/image/iconesMobile/emojiLogout.svg" width="auto" height="20px">
        <br />
        <strong v-if="(this.sizeWindow >= 768)">{{ user.displayName }}!</strong>
      </p>
    </div>
    <h1 class="yourBox">Seu caixa fechará assim:</h1>
    <div class="col-md-12 px-0 mb-5" id="yourBoxSub"></div>
    <div class="col-md-12" v-if="(this.sizeWindow >= 768)">
      <div class="row containerPage">
        <div class="col-md-2 centerFlex">
          <div class="row">
            <div class="col-md-5 dia">
              <p class="titulo_dash mb-2 text-center fontLarge">Dia</p>
            </div>
            <div class="col-md-7 vendedor">
              <p class="titulo_dash col text-center mb-2 fontLarge">Vendedor</p>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-left abertura">
          <p class="titulo_dash mb-2 fontLarge text-center">Abertura</p>
        </div>
        <div class="col-md-1 fechamento">
          <p class="titulo_dash md-8 text-center fontLarge">Fechamento</p>
        </div>

        <div class="col-md-4">
          <div class="row">
            <div class="col-md-2">
              <p
                class="titulo_dash mb-1 fontLarge"
                style="position: relative; left: -34px"
              >
                Vendas
              </p>
            </div>
            <div class="col-md-2">
              <p
                class="titulo_dash mb-1 fontLarge"
                style="position: relative; left: -34px"
              >
                Dinheiro
              </p>
            </div>
            <div class="col-md-2">
              <p
                class="titulo_dash mb-1 fontLarge"
                style="position: relative; left: -26px"
              >
                Cartão
              </p>
            </div>
            <div class="col-md-2">
              <p
                class="titulo_dash mb-1 fontLarge text-center"
                style="position: relative; left: -20px"
              >
                Pix / Transf
              </p>
            </div>
            <div class="col-md-2">
              <p
                class="titulo_dash mb-1 fontLarge"
                style="position: relative; left: -25px"
              >
                Retirada
              </p>
            </div>
            <div class="col-md-2">
              <p
                class="titulo_dash mb-1 fontLarge"
                style="position: relative; left: -10px"
              >
                Saldo Caixa
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-2 align-items-center diferenca">
          <p
            class="titulo_dash mb-2 fontLarge"
            style="position: relative; left: -24px"
          >
            Diferença
          </p>
        </div>
      </div>

      <div class="col-md-12 mb-3 px-0">
        <div class="container-xxl">
          <div class="row">
            <div class="col-md-2 text-center px-1">
              <div class="box">
                <div class="d-flex h-100 align-items-center">
                  <div class="col-md-5">
                    <p class="titulo_dash mb-0 titleMes">
                      {{ item.openingTime.substring(8, 10) }}
                    </p>
                  </div>
                  <div class="col-md-7">
                    <p class="titulo_dash mb-0">{{ item.userName }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 text-center px-1" style="max-width: 14%">
              <div class="box">
                <div class="d-flex h-100 align-items-center">
                  <div class="col-md-3">
                    <p class="titulo_dash mb-0">
                      {{ item.openingTime.substring(11, 16) }}
                    </p>
                  </div>
                  <div class="col-md-auto mx-auto">
                    <p class="titulo_dash mb-0 text-center">
                      {{ item.inicialValue | currency }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1 text-center px-1 mr-3">
              <div class="box">
                <div class="d-flex h-100 align-items-center">
                  <div class="col-md-12 mx-auto">
                    <p class="titulo_dash mb-0">
                      {{ item.endValue | currency }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center px-0 ml-0 mr-3">
              <div class="rowFlex">
                <div class="box col-md-2 mr-1 centersContainer">
                  <div
                    class="
                      d-flex
                      h-100
                      align-items-center
                      mx-auto
                      d-flex
                      h-100
                      align-items-center
                      centers
                    "
                  >
                    <p class="titulo_dash mb-0 text-center">
                      {{ item.moviment | currency }}
                    </p>
                  </div>
                </div>
                <div
                  class="box col-md-2 mr-1 centersContainer"
                  v-for="(itensPays, index) in item.cashierCoins"
                  :key="index"
                >
                  <div class="d-flex h-100 align-items-center">
                    <p class="titulo_dash mb-0 text-center">
                      {{ itensPays.valuePayment | currency }}
                    </p>
                  </div>
                </div>
                <div class="box col-md-2 mr-1 centersContainer">
                  <div class="d-flex h-100 align-items-center">
                    <p class="titulo_dash mb-0 text-center">
                      {{ item.withdrawal | currency }}
                    </p>
                  </div>
                </div>
                <div class="box col-md-2 mr-1 centersContainer">
                  <div class="d-flex h-100 align-items-center text-center">
                    <p class="titulo_dash mb-0 text-center">
                      {{ item.balance | currency }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-3 d-flex">
              <!-- <div class="row"> -->
                <div
                  class="box col-md-5 px-1 ml-1 mr-1"
                  :style="item.difference >= 0 ? 'background-color: #35b890;' : 'background-color: #E67979;'"
                >
                  <div
                    class="d-flex h-100 align-items-center"
                    data-placement="right"
                  >
                    <div class="col-md-12 text-center">
                      <p class="titulo_dash mb-0">
                        {{ item.difference | currency }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="box col-md-5 px-1 ml-1 mr-1"
                  :style="item.printsNonRelational > 0 ? 'background-color: #E67979;' : 'background-color: #35b890;'"
                >
                  <div
                    class="d-flex h-100 align-items-center"
                    data-placement="right"
                  >
                    <div class="col-md-12 text-center">
                      <p class="titulo_dash mb-0">
                        <strong> {{ item.printsNonRelational }} </strong> Imp
                      </p>
                    </div>
                  </div>
                </div>
                    <div class="col-md">
                      <listCsvVue :id="item.id" />
                    </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="allItensCont" v-if="(this.sizeWindow < 768)">
      <div class="allItensSubCont">
        <div class="itenCont">
          <p class="nameOf">Dia</p>
          <p class="infoOf">{{ item.openingTime.substring(8, 10) }}</p>
        </div>
        <div class="itenCont">
          <p class="nameOf">Vendedor</p>
          <p class="infoOf">{{ item.userName }}</p>
        </div>
        <div class="itenCont">
          <p class="nameOf">Abertura</p>
          <p class="infoOf">{{ item.openingTime.substring(11, 16) }} {{ item.inicialValue | currency }}</p>
        </div>
        <div class="itenCont">
          <p class="nameOf">Fechamento</p>
          <p class="infoOf">{{ item.endValue | currency }}</p>
        </div>
        <div class="itenCont">
          <p class="nameOf">Vendas</p>
          <p class="infoOf">{{ item.moviment | currency }}</p>
        </div>
        <div class="itenCont">
          <p class="nameOf">Dinheiro</p>
          <p class="infoOf">{{ item.cashierCoins[0].valuePayment | currency }}</p>
        </div>
        <div class="itenCont">
          <p class="nameOf">Cartão</p>
          <p class="infoOf">{{ item.cashierCoins[1].valuePayment | currency }}</p>
        </div>
        <div class="itenCont">
          <p class="nameOf">Pix/Transf</p>
          <p class="infoOf">{{ item.cashierCoins[2].valuePayment | currency }}</p>
        </div>
        <div class="itenCont">
          <p class="nameOf">Retirada</p>
          <p class="infoOf">{{ item.withdrawal | currency }}</p>
        </div>
        <div class="itenCont">
          <p class="nameOf">Saldo Caixa</p>
          <p class="infoOf">{{ item.balance | currency }}</p>
        </div>
        <div class="difAndImp" :style="item.difference >= 0 && item.printsNonRelational <= 0 ?'background-color: #C3FFC1;'  : 'background-color: #E67979;' ">
          <div class="itenCont">
            <p class="nameOf">DIFERENÇA</p>
            <p class="infoOf">{{ item.difference | currency }}</p>
          </div>
          <div class="itenCont">
            <p class="nameOf">IMPRESSÔES</p>
            <p class="infoOf">{{ item.printsNonRelational }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <h3>Confirmar?</h3>
      <div class="buttonsContBtns">
        <button class="btn_default" @click="confirmButton">
          Sim! Fechar
        </button>
        <button class="btn_default" id="secondButton" @click="$emit('back', 'fechar_caixa')">
          Não! Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import btn from "@/components/caixacp/_Caixa_btn";
import listCsvVue from "../CSV/listCsv.vue";
export default {
  props: ["item"],
  components: { btn, listCsvVue },
  data(){
    return{
      sizeWindow: 0
    }
  },
  methods:{
    confirmButton(){
      this.$emit('finish')
    }
  },
  mounted(){
    this.sizeWindow = $(window).width();
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    }
  }
};
</script>

<style scoped>
.image_div {
  overflow: hidden;
  width: 80px;
  overflow: hidden;
  height: 80px;
  border-radius: 50%;
  border: 5px solid #2474b2;
}

.image_div .img_user {
  object-fit: cover;
  height: auto;
  min-height: 100%;
  width: 100%;
  object-position: top;
}
.nameAndImg{
	display: flex;
	flex-direction: row;
	align-items: center;
  justify-content: center;
	margin-bottom: 4%;
}
.name{
	display: flex;
  text-align: initial;
	color:#F38235;
	margin-left: 10px;
}
.titleMobile{
	font-size: 17px;
}
.title{
    font-size: 17px;
    color: #A5A6AD;
  }
.containerCaixa {
  background: #fff;
  border-radius: 25px;
  margin: 30px;
  max-height: 50vh;
  width: 90vw;
  max-width: 1265px;
}

.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
}

.btns > div {
  display: flex;
}

h1,
h3 {
  margin: 10px 0;
  text-align: center;
  font-size: 27px;
  color: rgb(12, 33, 114);
}

/* p {
  color: #000;
} */

.titleMes {
  color: #e0a638;
}

.box {
  height: 80px;
  background-color: #357eb8;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}
.dia {
  left: -23px;
}
.abertura {
  left: -20px;
}
.vendedor {
  left: -13px;
}
.fechamento {
  left: -35px;
}
.diferenca {
  left: 10px;
  text-align: center;
  margin: 0 auto;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
  margin: 10px;
  width: auto;
  padding: 10px;
}
.btn_default:hover {
  border-radius: 7px;
  font-weight: 500;
  border: none;
  background-color: #f38235;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #ffff;
}

.containerPage p {
  color: rgb(12, 33, 114);
}
.rowFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centersContainer {
  display: flex;
  justify-content: center;
}

.centers {
  margin: 0 auto 0 4px;
}

.centerFlex {
  display: flex;
  justify-content: flex-end;
}
.allItensCont{
  display: flex;
  justify-content: center;
  background-color: #E9E9EB;
  border-radius: 16px;
}
.allItensSubCont{
  width: 96%;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 330px;
  overflow: auto;
  border-radius: 16px;
  background-color: #FFFF;
  margin: 12px;
}
.itenCont{
  border-bottom: #E9E9EB 1px solid;
  width: 100%;
  text-align: start;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
}
.itenCont p{
  margin-bottom: 0.3rem;
}
.difAndImp{
  width: 100%;
}
.nameOf{
  font-size: 10px;
  color: #A5A6AD;
}
.infoOf{
  font-size: 14px;
  color: #4C4D5B;
}

@media screen and (max-width:767px) {
  .containerCaixa {
    margin: 0;
    width: 100%;
    max-height: 75vh;
}
.yourBox{
  margin: 10px 0;
    text-align: center;
    font-size: 16px;
    color: #797984;
}
#yourBoxSub{
  margin-bottom: 0rem!important;
}
h3{
  margin: 10px 0;
  text-align: center;
  font-size: 18px;
  color:#1B60A8;
  margin-bottom: 0;
  margin-top: 4px;
}
.btns{
    margin-top: 5px;
}
.btns button{
  margin: 4px;
}
.buttonsContBtns{
  display: flex;
  flex-direction: column;
  width: 80%
}
#secondButton{
  border: 2px solid #3F88C1;
  background-color: #FFFF;
  color: #2474B2
}
@media screen and (max-height:670px){
  .allItensSubCont{
    max-height: 256px!important;
  }

  .containerCaixa {
    max-height: 83vh!important;
  }
  .image_div{
    width: 75px!important;
    height: 75px!important;
  }
  .image_div img{
    width: 100%;
  }
}
}
</style>
