<template>
  <div
    class="abrir_caixa row justify-content-center d-flex align-items-baseline"
  >

  <div class="nameAndImg" v-if="(this.sizeWindow < 768)">
			<div class="imgName">
				<div class="image_div position-relative mx-auto">
					<img :src="getImg(user.photo)" class="img_user" @error="errorImage" />
				</div>
			</div>
			<div class="name">
				<p class="titleMobile">
				Olá,
				<br />
				<strong>{{ user.displayName }}!</strong>
				</p>
			</div>
		</div>
    <div class="col-md-12 pr-0 text-center">
      <p class="title">
        Obrigado pelo trabalho<img v-if="(this.sizeWindow < 768)" src="../../assets/image/iconesMobile/emojiLogout.svg" width="auto" height="20px">
        <br />
        <strong v-if="(this.sizeWindow >= 768)">{{ user.displayName }}!</strong>
      </p>
    </div>
    <div class="col-md-12">
      <p class="texto text-left">
        Informe, por favor, o
        <strong>fechamento de caixa do dia.</strong>
      </p>
    </div>

    <div class="caixa_cp px-0 my-2">
      <caixa
        class="col-md-12"
        @changeValueNota="changeValueNota"
        @changeValueCatao="changeValuecartao"
        :valuesPay="valuesPays"
      />
    </div>

    <div v-show="button" style="width: 100%" >
      <btn v-if="(this.sizeWindow >= 768)"
        msg="Fechar caixa"
        class="col-md-10 mx-auto"
        v-show="$parent.cp == 'fechar_caixa'"
        @trocaComponent="trocaComponent"
      />
      <btn v-if="(this.sizeWindow < 768)"
        msg="Continuar"
        class="col-md-10 mx-auto"
        v-show="$parent.cp == 'fechar_caixa'"
        @trocaComponent="trocaComponent"
      />
    </div>

    <div class="col-md-12">
      <p class="textos">
        Valores com Pix/Transf e Cartão não serão somados no caixa.
      </p>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
import caixa from "../caixacp/_Caixa";
import btn from "../caixacp/_Caixa_btn";
export default {
  components: {
    caixa,
    btn,
  },
  data() {
    return {
      totalValue: 0,
      totalValueCartao: 0,
      nameComponent: "fecharCaixa",
      valuesPays: [],
      button: false,
      sizeWindow: 0
    };
  },
  mounted() {
    this.valuesPay();
    this.sizeWindow = $(window).width();
  },
  methods: {
    trocaComponent(e) {
      //console.log(e)
      this.$emit("trocaComponent", e);
    },
    changeValueNota(value) {
      this.totalValue = value;
    },
    changeValuecartao(value) {
      this.totalValueCartao = value;
    },
    valuesPay() {
      HTTP.post(
        `services/app/Cashier/ShowClosingValues?idCashier=${this.$store.state.caixa.idCaixa}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`,
          },
        }
      )
        .then((res) => {
          this.valuesPays = res.data.result;
          this.button = true;
        })
        .catch((err) => console.log(err, "não carregou os valores"));
    },
  },
  computed: {
    user() {
      return this.$store.state.login.userLogin;
    },
  },
};
</script>

<style scoped>
.input_default {
  color: #2474b2;
}
.title strong {
  margin-bottom: 0.1rem !important;
  color: #2474b2;
}
.texto {
  color: #868686 !important;
  font-size: 13px;
}
.textos {
  color: #868686 !important;
  font-size: 13px;
  text-align: center;
  margin: 10px;
}
.title {
  color: #868686 !important;
}

.image_div {
  overflow: hidden;
  width: 100px;
  overflow: hidden;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #2474b2;
}


.caixa_cp {
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}
/* width */
.caixa_cp::-webkit-scrollbar {
  width: 5px;
  background: #2474b2;
}

/* Track */
.caixa_cp::-webkit-scrollbar-track {
  border-radius: 0px;
}

/* Handle */
.caixa_cp::-webkit-scrollbar-thumb {
  background: #2474b2;
  border-radius: 0px;
}
.nameAndImg{
	display: flex;
	flex-direction: row;
	align-items: center;
    justify-content: center;
	margin-bottom: 4%;
}
.name{
	display: flex;
  text-align: initial;
	color:#F38235;
	margin-left: 10px;
}
.titleMobile{
	font-size: 17px;
}
@media screen and (max-width:767px) {
  .texto{
    font-size: 17px;
    text-align: center!important;
  }
  .title{
    font-size: 17px;
    text-align: center;
  }
  .caixa_cp{
    margin-bottom: 2.5rem!important;
    max-height: 275px!important;
  }
}
@media screen and (max-height:670px) {
  .image_div{
    width: 75px!important;
    height: 75px!important;
  }
  .image_div img{
    width: 100%;
  }
  .caixa_cp{
    margin-bottom: 1.5rem!important;
    max-height: 275px!important;
  }
}

</style>
