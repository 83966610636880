<template>
<div @click="requestItem">
    <vue-json-to-csv :json-data="dataJson" :labels="{
        serviceName: { title: 'Serviço' },
        date: { title: 'Data e Hora' },
        nameBySalesman: { title: 'Nome do Vendedor' },
        amount: { title: 'valor' },
        paymentType: { title: 'Tipo de Pagamento' }
      }" :csv-title="'Relatorio'" :separator="';'"
        @success="val => handleSuccess(val)"
        @error="val => handleError(val)"
      >
        <button :class="`downloadCsv`">
            <font-awesome-icon icon="fa-solid fa-download" />
        </button>
    </vue-json-to-csv>
    <!-- <button v-else class="downloadCsv error">
        <font-awesome-icon icon="fa-solid fa-download" />
    </button> -->

</div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
import { mapActions } from 'vuex'
export default {
  props: ['id'],
  components: {
    VueJsonToCsv
  },
  data () {
    return {
      dataJson: []
    }
  },
  methods: {
    ...mapActions(['requestDataListCsv']),
    async requestItem () {
      // @success="val => handleSuccess(val)" @error="val => handleError(val)"
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        this.$emit("showModalDegustacao");
      } else {
        try {
          const { data } = await this.requestDataListCsv(this.id)
          const convertValue = data.result.map(item => {
            const value = item.amount.toFixed(2).replace('.', ',')
            return { ...item, amount: value }
          })
          this.dataJson = convertValue
        } catch (error) {
          this.toast(
            error.response.data.error.message,
            this.$toast.error
          )
        }
      }
    },
    handleSuccess (e) {
      console.log(e)
      this.toast(
        'Aguarde',
        this.$toast
      )

      this.requestItem()
    },
    handleError (e) {
      console.log(e, 'erro')
      this.toast(
        'Ops, erro ao carregar os dados.',
        this.$toast.error
      )
    }
  }
}
</script>

<style scoped>
.downloadCsv {
    border: none;
    background: none;
    color: #e0a638;
    font-size: 20px;
    height: 80px;
    outline: none;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.downloadCsv:hover {
    color: #f38235;
}

.error {
    color: #E67979;
}

.error:hover {
    color: #E67979;
}
</style>
